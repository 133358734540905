.footer {
  background-color: rgb(233, 236, 239);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  padding: 4px;
}

.contact-icons {
  width: 40px;
  height: 40px;
  margin: 10px;
}

/* ----------------------- Media Queries  - Mobile -----------------------  */
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .copyright-name {
    font-size: 14px;
    margin: 0;
  }

  .icon-bar {
    display: flex;
    flex-direction: row;
  }

  .contact-icons {
    width: 30px;
    height: 30px;
  }
}

/* ----------------------- Media Queries  - Tablet -----------------------  */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .copyright-name {
    font-size: 16px;
    margin: 0;
  }

  .contact-icons {
    width: 30px;
    height: 30px;
  }
}

/* ----------------------- Media Queries  - Tablet -----------------------  */
@media only screen and (min-width: 1025px) and (max-width: 1920px) {
}
