.header {
  background-color: rgba(247, 228, 170, 0.5);
  display: flex;
  justify-content: space-between;
  align-content: center;
}

.nav-item {
  margin: auto 20px;
  color: #5d8aa6;
}
