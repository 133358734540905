@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&family=Montserrat:wght@200;300;400;500;600;700&display=swap');

.App {
  text-align: center;
  background-color: rgb(233, 236, 239);
  /* background-color: rgba(247, 228, 170, 0.6); */
  background-size: cover;
  display: flex;
  flex-direction: column;
  color: #5d8aa6;
  font-family: 'Montserrat', sans-serif;
}

html {
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
  color: #5d8aa6;
}
