@import url(https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&family=Montserrat:wght@200;300;400;500;600;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  background-color: rgb(233, 236, 239);
  /* background-color: rgba(247, 228, 170, 0.6); */
  background-size: cover;
  display: flex;
  flex-direction: column;
  color: #5d8aa6;
  font-family: 'Montserrat', sans-serif;
}

html {
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
  color: #5d8aa6;
}

.header {
  background-color: rgba(247, 228, 170, 0.5);
  display: flex;
  justify-content: space-between;
  align-content: center;
}

.nav-item {
  margin: auto 20px;
  color: #5d8aa6;
}

.jumbotron {
  font-family: 'Comfortaa', cursive;
}

.title {
  font-size: 4.5rem;
  font-weight: lighter;
}
.subtitle {
  color: #686868;
  font-size: 20px;
}

/* ----------------------- Media Queries  - Mobile -----------------------  */
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .jumbotron {
    text-align: center;
  }

  .title {
    font-size: 2.8rem;
    font-weight: lighter;
  }
  .subtitle {
    font-size: 16px;
  }
}

/* ----------------------- Media Queries  - Tablet -----------------------  */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .jumbotron {
    text-align: center;
  }
}

/* ----------------------- Media Queries  - Tablet -----------------------  */
@media only screen and (min-width: 1025px) and (max-width: 1920px) {
}

.about-me {
  background-color: rgba(247, 228, 170, 0.5);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  color: #686868;
}

.skills-container {
  margin: 35px;
}

.skills-icon {
  margin: 10px;
}

.brand-statement {
  margin-top: 30px;
}

/* ----------------------- Media Queries  - Mobile -----------------------  */
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .about-me {
    width: 100%;
  }

  .skills-container {
    margin: 10px;
  }

  .skills-icon {
    width: 60px;
    height: 60px;
  }
}

/* ----------------------- Media Queries  - Tablet -----------------------  */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .about-me {
    width: 100%;
    font-size: 20px;
  }

  .skills-container {
    margin: 10px;
  }

  .skills-icon {
    width: 80px;
    height: 80px;
  }
}

/* ----------------------- Media Queries  - Tablet -----------------------  */
@media only screen and (min-width: 1025px) and (max-width: 1920px) {
}

.projects {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: #686868;
}

.project-columns {
  margin: 10px;
}

.project-cards {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: transparent;
}

.project-buttons {
  margin: 10px;
}

.button-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.video-clip {
  width: 260px;
  height: 200px;
}


.contact-container {
  background-color: rgba(247, 228, 170, 0.5);
  padding: 30px;
}

.contact-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #5d8aa6;
  font-family: 'Comfortaa', cursive;
}

.contact-input {
  width: 400px;
  margin: 10px;
}

.form-group-message {
  width: 400px;
  height: 200px;
  margin: 10px;
}

.send-button {
  margin-top: 10px;
  border-radius: 4px;
  border: 2px solid #5d8aa6;
  background-color: transparent;
  color: #5d8aa6;
}

/* ----------------------- Media Queries  - Mobile -----------------------  */
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .contact-container {
    padding: 40px;
  }

  .contact-input {
    width: 280px;
  }

  .form-group-message {
    width: 280px;
  }

  .send-button {
    width: 100px;
    height: 40px;
    font-size: 25px;
  }
}

/* ----------------------- Media Queries  - Tablet -----------------------  */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .send-button {
    width: 90px;
    height: 40px;
    font-size: 20px;
  }
}

/* ----------------------- Media Queries  - Tablet -----------------------  */
@media only screen and (min-width: 1025px) and (max-width: 1920px) {
}

.footer {
  background-color: rgb(233, 236, 239);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  padding: 4px;
}

.contact-icons {
  width: 40px;
  height: 40px;
  margin: 10px;
}

/* ----------------------- Media Queries  - Mobile -----------------------  */
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .copyright-name {
    font-size: 14px;
    margin: 0;
  }

  .icon-bar {
    display: flex;
    flex-direction: row;
  }

  .contact-icons {
    width: 30px;
    height: 30px;
  }
}

/* ----------------------- Media Queries  - Tablet -----------------------  */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .copyright-name {
    font-size: 16px;
    margin: 0;
  }

  .contact-icons {
    width: 30px;
    height: 30px;
  }
}

/* ----------------------- Media Queries  - Tablet -----------------------  */
@media only screen and (min-width: 1025px) and (max-width: 1920px) {
}

