.contact-container {
  background-color: rgba(247, 228, 170, 0.5);
  padding: 30px;
}

.contact-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #5d8aa6;
  font-family: 'Comfortaa', cursive;
}

.contact-input {
  width: 400px;
  margin: 10px;
}

.form-group-message {
  width: 400px;
  height: 200px;
  margin: 10px;
}

.send-button {
  margin-top: 10px;
  border-radius: 4px;
  border: 2px solid #5d8aa6;
  background-color: transparent;
  color: #5d8aa6;
}

/* ----------------------- Media Queries  - Mobile -----------------------  */
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .contact-container {
    padding: 40px;
  }

  .contact-input {
    width: 280px;
  }

  .form-group-message {
    width: 280px;
  }

  .send-button {
    width: 100px;
    height: 40px;
    font-size: 25px;
  }
}

/* ----------------------- Media Queries  - Tablet -----------------------  */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .send-button {
    width: 90px;
    height: 40px;
    font-size: 20px;
  }
}

/* ----------------------- Media Queries  - Tablet -----------------------  */
@media only screen and (min-width: 1025px) and (max-width: 1920px) {
}
