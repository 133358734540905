.projects {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: #686868;
}

.project-columns {
  margin: 10px;
}

.project-cards {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: transparent;
}

.project-buttons {
  margin: 10px;
}

.button-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.video-clip {
  width: 260px;
  height: 200px;
}
