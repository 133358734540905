.about-me {
  background-color: rgba(247, 228, 170, 0.5);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  color: #686868;
}

.skills-container {
  margin: 35px;
}

.skills-icon {
  margin: 10px;
}

.brand-statement {
  margin-top: 30px;
}

/* ----------------------- Media Queries  - Mobile -----------------------  */
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .about-me {
    width: 100%;
  }

  .skills-container {
    margin: 10px;
  }

  .skills-icon {
    width: 60px;
    height: 60px;
  }
}

/* ----------------------- Media Queries  - Tablet -----------------------  */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .about-me {
    width: 100%;
    font-size: 20px;
  }

  .skills-container {
    margin: 10px;
  }

  .skills-icon {
    width: 80px;
    height: 80px;
  }
}

/* ----------------------- Media Queries  - Tablet -----------------------  */
@media only screen and (min-width: 1025px) and (max-width: 1920px) {
}
