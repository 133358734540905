.jumbotron {
  font-family: 'Comfortaa', cursive;
}

.title {
  font-size: 4.5rem;
  font-weight: lighter;
}
.subtitle {
  color: #686868;
  font-size: 20px;
}

/* ----------------------- Media Queries  - Mobile -----------------------  */
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .jumbotron {
    text-align: center;
  }

  .title {
    font-size: 2.8rem;
    font-weight: lighter;
  }
  .subtitle {
    font-size: 16px;
  }
}

/* ----------------------- Media Queries  - Tablet -----------------------  */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .jumbotron {
    text-align: center;
  }
}

/* ----------------------- Media Queries  - Tablet -----------------------  */
@media only screen and (min-width: 1025px) and (max-width: 1920px) {
}
